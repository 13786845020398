import {ref, watch, computed} from '@vue/composition-api'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import {isEmpty} from "@core/utils/filter";

export default function customerUseList() {
    // Use toast
    const toast = useToast()
    const condition = ref({})
    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'company_id', label: 'ID', sortable: true},
        {key: 'one_company_id', label: '公司ONEID', sortable: true},
        {key: 'one_customer_id', label: '客户ONEID', sortable: true},
        {key: 'company_name', label: '公司名称', sortable: true},
        {key: 'nick_name', label: '公司简称', sortable: true},
        // {key: 'company_properties', label: '企业性质', sortable: true},
        // {key: 'uniform_social_credit_code', label: '统一社会信用代码', sortable: true},
        {key: 'company_kind', label: '客户类型', sortable: true},
        {key: 'pay_period', label: '付款周期', sortable: true},
        {key: 'status', label: '审核状态', sortable: true},
        {key: 'state', label: '启用', sortable: true},
        {key: 'create_time', label: '添加时间', sortable: true},
        {key: 'creator', label: '添加人', sortable: true},
        {key: 'update_time', label: '更新时间', sortable: true},
        {key: 'updator', label: '更新人', sortable: true},
        { key: 'custom_level', label: '客户等级', sortable: true },
        { key: 'level_code', label: '集团等级', sortable: true },
        {key: 'actions', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('company_id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey], () => {
        refetchData()
    })

    const searchList =  (ctx, callback) => {
        const storeArray = isEmpty(condition.value.storeArrays)?[]:condition.value.storeArrays
        axios.post('api/company/searchNew', {
            company_type: 6,
            company_name: condition.value.company_name,
            nick_name: condition.value.nick_name,
            uniform_social_credit_code: condition.value.uniform_social_credit_code,
            one_company_id: condition.value.one_company_id,
            status: condition.value.status,
            state: condition.value.state,
            custom_level: condition.value.custom_level,
            level_code: condition.value.level_code,
            company_kind:condition.value.company_kind,
            pay_period:condition.value.pay_period,
            storeIds:storeArray.join(','),
            search_key: searchKey.value,
            start: start.value,
            limit: limit.value,
            order_by: orderBy.value,
            order_desc: isSortDirDesc.value === true ? 'desc' : '',
        })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                // 需要异步
                // if(list.length>0){
                //     for (let i = 1;i < list.length;i++){
                //         axios.post('api/warehousestatement/editNew', {
                //             company_id:list[i].company_id
                //         }).then(
                //             res =>{
                //                 const data = res.data.data
                //                 list[i].pay_period = data.pay_period
                //             }
                //         )
                //     }
                // }
                listTotals.value = data.ext.totals
                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        condition,
        refetchData,

        // Extra Filters
    }
}
