<template>
  <div>
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--公司全称-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司全称"
                    label-for="company_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="company_name"
                      size="sm"
                      v-model="condition.company_name"
                  />
                </b-form-group>
              </b-col>
              <!--公司简称-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司简称"
                    label-for="nick_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="nick_name"
                      size="sm"
                      v-model="condition.nick_name"
                  />
                </b-form-group>
              </b-col>
              <!--统一社会信用代码-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="统一社会信用代码"
                    label-for="uniform_social_credit_code"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="uniform_social_credit_code"
                      size="sm"
                      v-model="condition.uniform_social_credit_code"
                  />
                </b-form-group>
              </b-col>
              <!--公司id-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司ONEID"
                    label-for="one_company_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="one_company_id"
                      size="sm"
                      type="number"
                      v-model="condition.one_company_id"
                  />
                </b-form-group>
              </b-col>
              <!--审核状态-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('company_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      placeholder="请选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--客户等级-->
              <b-col md="4">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="客户等级"
                        label-for="custom_level"
                        label-size="sm"
                        class="mb-1"
                >
                  <v-select
                          id="custom_level"
                          :options="getCodeOptions('custom_level')"
                          v-model="condition.custom_level"
                          class="select-size-sm"
                          placeholder="请选择客户等级"
                          :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--集团等级-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="集团等级"
                    label-for="level_code"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="level_code"
                      :options="level_code_options"
                      v-model="condition.level_code"
                      class="select-size-sm"
                      placeholder="请选择集团等级"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--启用-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="启用状态"
                    label-for="state"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="state"
                      :options="getCodeOptions('state')"
                      v-model="condition.state"
                      class="select-size-sm"
                      placeholder="请选择启用状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <!--客户类型-->
              <b-col md="4">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="客户类型"
                        label-for="company_kind"
                        label-size="sm"
                        class="mb-1"
                >
                  <v-select
                          id="company_kind"
                          :options="getCodeOptions('company_kind_ZDKH')"
                          v-model="condition.company_kind"
                          class="select-size-sm"
                          placeholder="请选择客户类型"
                          :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <!--付款周期  -->
              <b-col md="4">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="付款周期"
                        label-for="pay_period"
                        label-size="sm"
                        class="mb-1"
                >
                  <v-select
                          id="pay_period"
                          :options="getCodeOptions('preorder_pay_preiod')"
                          v-model="condition.pay_period"
                          class="select-size-sm"
                          placeholder="请选择付款周期"
                          :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <!--店铺团队-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺团队"
                    label-for="store_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="store"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      :options="getCodeOptions('store')"
                      v-model="condition.storeArrays"
                      class="select-size-sm"
                      placeholder="请选择店铺团队"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(company_id)="data">
          #{{ data.item.company_id }}
        </template>

        <!-- Column: Type -->
        <template #cell(company_properties)="data">
          {{ getCodeLabel('company_properties', data.item.company_properties) }}
        </template>


        <!-- 状态 -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('company_status', data.item.status)}`"
          >
            {{ getCodeLabel('company_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- 启用 -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- 添加时间       -->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人        -->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--客户类型        -->
        <template #cell(company_kind)="{value}">
          {{ getCodeLabel('company_kind_ZDKH', value) }}
        </template>

        <!--付款周期        -->
        <template #cell(pay_period)="data">
          {{ getCodeLabel('preorder_pay_preiod', data.item.ext.pay_period) }}
        </template>

        <!-- 更新时间       -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!-- 客户等级       -->
        <template #cell(custom_level)="data">
          {{ getCodeLabel('custom_level',data.item.custom_level)}}
        </template>

        <!--更新人        -->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-company-offlineCustomer-new-edit', query: { id: data.item.id } }" v-if="data.item.status === 2 || data.item.status === 5 || data.item.status === 3 || data.item.status === 1">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <!--审核通过后编辑-->
            <b-dropdown-item :to="{ name: 'apps-company-offlineCustomer-new-edit', query: { id: data.item.id,afterAudit:1 } }" v-if="data.item.status === 4">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="audit(data.item.id,3,'确定提交审核吗？')" v-if="data.item.status === 2">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status == 4" @click="showCheckModal(data.item)">
              <feather-icon icon="Edit3Icon"/>
              <span class="align-middle ml-50">客户名称变更</span>
            </b-dropdown-item>

            <div v-if="user.role_id === 14 || user.role_id === 33 || user.role_id === 29">
              <b-dropdown-item @click="audit(data.item.id,4,'确定审核通过吗？')" v-if="data.item.status === 3">
                <feather-icon icon="CheckIcon"/>
                <span class="align-middle ml-50">审核通过</span>
              </b-dropdown-item>

              <b-dropdown-item @click="audit(data.item.id,5,'确定审核不通过吗？')" v-if="data.item.status === 3">
                <feather-icon icon="XIcon"/>
                <span class="align-middle ml-50">审核不通过</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>


      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <!--变更客户名称-->
      <b-modal
          id="checkModal"
          @ok="finishCheck"
          ok-title="提交"
          cancel-title="取消"
          centered
          size="lg"
          title="客户名称变更"
      >
        <b-row>
          <!--旧名称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="旧名称"
                label-for="company_name_old"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="company_name_old"
                  size="sm"
                  readonly
                  v-model="companycheck.company_name_old"
              />
            </b-form-group>
          </b-col>
          <!--新名称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="新名称"
                label-for="company_name_new"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="company_name_new"
                  size="sm"
                  v-model="companycheck.company_name_new"
              />
            </b-form-group>
          </b-col>
          <!--旧简称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="旧简称"
                label-for="nick_name_old"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="nick_name_old"
                  size="sm"
                  readonly
                  v-model="companycheck.nick_name_old"
              />
            </b-form-group>
          </b-col>
          <!--新简称-->
          <b-col md="6">
            <b-form-group
                label-cols="2"
                label-cols-lg="2"
                label="新简称"
                label-for="nick_name_new"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="nick_name_new"
                  size="sm"
                  v-model="companycheck.nick_name_new"
              />
            </b-form-group>
          </b-col>
          <!--变更原因-->
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="变更原因"
                label-for="reason"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="textarea"
                  v-model="companycheck.reason"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
  </div>

</template>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import customerUseList from './offlinecustomerUseList'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import companyStore from "@/views/apps/company/companyStore";
import axios from '@axios'
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        mode: 'range',
      },
      user: {},
      companycheck: {},
      level_code_options:[{label:'S',value:'S'},{label:'A',value:'A'},{label:'B',value:'B'},{label:'C',value:'C'}]
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('companystore')) store.registerModule('companystore', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companystore')) store.unregisterModule('companystore')
    })


    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const audit = function (id, status, title, text) {
      axios.post('api/company/customerAudit', {company_id: id, status: status}).then(res => {
        if (res.data.code == 0) {
          toast.success("已提交")
          //重新加载表格
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const showCheckModal = function (item) {
      this.companycheck.company_id = item.id
      this.companycheck.company_name_old = item.company_name
      this.companycheck.nick_name_old = item.nick_name
      this.companycheck.company_name_new = ''
      this.companycheck.nick_name_new = ''
      this.companycheck.reason = ''
      this.$forceUpdate()
      this.$bvModal.show('checkModal')

    }

    const finishCheck = function (checkModal) {
      if (isEmpty(this.companycheck.company_name_new) && isEmpty(this.companycheck.nick_name_new)) {
        toast.error('变更名称、变更简称不能都为空！')
        checkModal.preventDefault();
        return false
      }
      axios.post('api/companycheck/save', this.companycheck).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: '已提交!',
            text: '请等待管理员审核',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.$swal({
            icon: 'error',
            title: '提交失败!',
            text: res.data.data,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })

    }

    const changeState = function (data) {
      if(data.status == 4){
        const state = 1 - data.state
        axios.post('api/company/oneState', {id: data.id, state: state,type:3}).then(res => {
          data.state = state
          if(res.data.code == 1){
            toast.error(res.data.data)
          }else {
            toast.success('更新成功')
          }
          refetchData()
        })
      }else {
        toast.error('请先审核')
      }
    }

    const exportExcel = function () {
      const storeArray = isEmpty(condition.value.storeArrays)?[]:condition.value.storeArrays
      const params = {
        company_type: 6,
        company_name: condition.value.company_name,
        nick_name: condition.value.nick_name,
        uniform_social_credit_code: condition.value.uniform_social_credit_code,
        one_company_id: condition.value.one_company_id,
        status: condition.value.status,
        state: condition.value.state,
        storeIds:storeArray.join(','),
      }
      axios({
        url: 'api/exportexcelfile/exportCompany',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '终端客户' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = customerUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      audit,
      showCheckModal,
      finishCheck,
      changeState,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
